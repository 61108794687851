.page-top {
  background-color: $sidebar;
  position: fixed;
  z-index: 904;
  box-shadow: 2px 0px 3px rgba(0, 0, 0, 0.5);
  height: 66px;
  width: 100%;
  min-width: $resMin;
  padding: 0 10px 0 40px;

  &.scrolled {
    background-color: rgba(black, 0.85)
  }
}

a.al-logo {
  color: $sidebar-text;
  display: block;
  font-size: 24px;
  font-family: $font-family;
  white-space: nowrap;
  float: left;
  outline: none !important;
  line-height: 60px;

  span {
    color: $primary;
  }
}

.user-profile {
  float: right;
  min-width: 230px;
  margin-top: 2px;
}

.al-user-profile {
  float: right;
  margin-right: 12px;
  transition: all .15s ease-in-out;
  padding: 0;
  height: 36px;
  border: 0;
  opacity: 1;
  position: relative;
  a {
    display: block;
  }
  img {
    width: 150px;
    position: relative;
    top: 5px;
    cursor: pointer;
  }
}

a.refresh-data {
  color: $sidebar-text;
  font-size: 13px;
  text-decoration: none;
  font-weight: $font-normal;
  float: right;
  margin-top: 13px;
  margin-right: 26px;

  &:hover {
    color: $warning !important;
  }
}

a.collapse-menu-link {
  font-size: 31px;
  cursor: pointer;
  display: block;
  text-decoration: none;
  line-height: 42px;
  color: $sidebar-text;
  padding: 0;
  float: left;
  margin: 11px 0 0 25px;

  &:hover {
    text-decoration: none;
    color: $warning;
  }
}

.al-skin-dropdown {
  float: right;
  margin-top: 14px;
  margin-right: 26px;

  .tpl-skin-panel {
    max-height: 300px;
    overflow-y: scroll;
    overflow-x: hidden;
  }
}

.icon-palette {
  display: inline-block;
  width: 14px;
  height: 13px;
  @include bg('theme/palette.png');
  background-size: cover;
}

.search {
  text-shadow: none;
  color: $sidebar-text;
  font-size: 13px;
  line-height: 25px;
  transition: all 0.5s ease;
  white-space: nowrap;
  overflow: hidden;
  width: 162px;
  float: left;
  margin: 20px 0 0 30px;

  label {
    cursor: pointer;
  }
  i {
    width: 16px;
    display: inline-block;
    cursor: pointer;
    padding-left: 1px;
    font-size: 16px;
    margin-right: 13px;
  }
  input {
    background: none;
    border: none;
    outline: none;
    width: 120px;
    padding: 0;
    margin: 0 0 0 -3px;
    height: 27px;
  }
}

@media screen and (max-width: $resS) {
  .search {
    display: none;
  }
}

@media screen and (max-width: $resXS) {
  .page-top {
    padding: 0 20px;
  }
}

@media (max-width: $resXXS) {
  .user-profile{
    min-width: 136px;
  }
  a.refresh-data {
    margin-right: 10px;
  }
  a.collapse-menu-link {
    margin-left: 10px;
  }

  .al-skin-dropdown {
    display: none;
  }
}

.profile-toggle-link{
  cursor: pointer;
}
